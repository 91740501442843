import * as Sentry from '@sentry/browser';

/**
 * Sentry initialization for BROWSER mode
 */
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://8a8980bedeca4017994574d7551f9b59@appmon.catalyst.net.nz/71',
    release: process.env.REACT_APP_APPLICATION_VERSION,
    ignoreErrors: [/ResizeObserver loop/],
  });
}
