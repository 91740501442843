import React, {
  useRef,
  useCallback,
  ChangeEventHandler,
  useEffect,
  useState,
  ChangeEvent,
  RefObject,
  KeyboardEventHandler,
  FocusEventHandler,
  useMemo,
} from 'react';
import padStart from 'lodash/padStart';
import './DateInput.scss';

export interface DateInputState {
  day: string;
  month: string;
  year: string;
  hour: string;
  minute: string;
}

export type DateInputOnChangeFn = (
  value: string,
  state: DateInputState
) => void;

export interface DateInputProps {
  // expects ISO format string YYYY-MM-DDTHH:mm:ss(Z)
  // if isDateOnly, it expects YYYY-MM-DD
  value: string;
  onChange: DateInputOnChangeFn;
  onFocus?: FocusEventHandler;
  onBlur?: FocusEventHandler;
  id?: string;
  autoFocus?: boolean;
  isDateOnly?: boolean;
  disabled?: boolean;
  placeholder?: Partial<DateInputState>;
  defaultValues?: Partial<DateInputState> | null;
  'data-testid'?: string;
  name?: string;
}

const MAXLEN = {
  day: 2,
  month: 2,
  year: 4,
  hour: 2,
  minute: 2,
};

const RANGE = {
  day: [0, 31],
  month: [0, 12],
  year: [0, 9999],
  hour: [0, 23],
  minute: [0, 59],
};

/**
 * A fairly dumb DateTime component with inputs for each day/month/year hour/time element.
 * The onChange() callback is called with whatever the value you entered, no validation is done,
 * nor it try to deal with timezone.
 *
 * However, there are some cool simple things about this component.
 *
 * It will try to prevent user to enter dumb data like:
 * - any number > 31 for day
 * - any number > 12 for month
 * - any number > 23 for hour
 * - any number > 59 for minute
 * - or any text
 *
 * When enough character is filled for one input, it will switch the focus to the next input field automatically.
 *
 * It also handles Backspace press when the input is empty, and move the focus to the previous input.
 */
export const DateInput = React.memo((props: DateInputProps) => {
  const {
    id,
    value,
    autoFocus,
    placeholder,
    isDateOnly,
    disabled,
    onChange,
    onFocus,
    onBlur,
    defaultValues,
  } = props;
  const [internalState, setInternalState] = useState<DateInputState>(
    convertValueToState(value, Boolean(isDateOnly))
  );

  const inputDay = useRef<HTMLInputElement>(null);
  const inputMonth = useRef<HTMLInputElement>(null);
  const inputYear = useRef<HTMLInputElement>(null);
  const inputHour = useRef<HTMLInputElement>(null);
  const inputMinute = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autoFocus) {
      moveFocusToElementIf(inputDay, true);
    }
  }, [autoFocus]);

  useEffect(() => {
    setInternalState((currentState) => {
      const computedValue = convertStateToValue(
        currentState,
        Boolean(isDateOnly),
        defaultValues
      );

      if (value === computedValue) {
        return currentState;
      }

      return convertValueToState(value, Boolean(isDateOnly));
    });
  }, [value, isDateOnly, defaultValues]);

  //
  // Set up focus and blur event handlers for the combined set of input elements.
  //
  const hasFocus = useRef<boolean>(false);
  const {
    handleFocus,
    handleBlur,
  }: {
    handleFocus?: FocusEventHandler<HTMLInputElement>;
    handleBlur?: FocusEventHandler<HTMLInputElement>;
  } = useMemo(() => {
    if (!onBlur && !onFocus) {
      // No onBlur or onFocus prop; no need for blur/focus event handlers.
      return {};
    } else {
      return {
        // Focus: When none of our inputs were focused, and then one receives focus.
        handleFocus: function (e) {
          if (onFocus && !hasFocus.current) {
            onFocus(e);
          }
          hasFocus.current = true;
        },

        // Blur: When none of our inputs have focus any longer.
        handleBlur: function (e) {
          // relatedTarget = the next element receiving focus
          const newFocus = e.relatedTarget;
          if (
            newFocus === inputDay.current ||
            newFocus === inputHour.current ||
            newFocus === inputMinute.current ||
            newFocus === inputMonth.current ||
            newFocus === inputYear.current
          ) {
            hasFocus.current = true;
          } else {
            hasFocus.current = false;
            if (onBlur) {
              onBlur(e);
            }
          }
        },
      };
    }
  }, [onBlur, onFocus]);

  const handleKeyDown: (type: keyof DateInputState) => KeyboardEventHandler =
    useCallback(
      (type) => (e) => {
        // if user press Backspace and the value is empty
        // move focus to previous input
        if (e.keyCode === 8) {
          const { minute, hour, year, month } = internalState;
          const movements = [
            moveFocusToElementIf(inputHour, type === 'minute' && !minute),
            moveFocusToElementIf(inputYear, type === 'hour' && !hour),
            moveFocusToElementIf(inputMonth, type === 'year' && !year),
            moveFocusToElementIf(inputDay, type === 'month' && !month),
          ];
          if (movements.some((mov) => Boolean(mov))) {
            // need to prevent default otherwise the the
            // next input in focus will have existing text deleted
            e.preventDefault();
          }
        }
      },
      [internalState]
    );

  const handleChange: (
    type: keyof DateInputState
  ) => ChangeEventHandler<HTMLInputElement> = useCallback(
    (type) => (e) => {
      try {
        const maxLen = MAXLEN[type];
        const range = RANGE[type] as [number, number];
        const val = getValidValue(e, maxLen, range);
        moveFocusToElementIf(inputMonth, type === 'day' && val.length === 2);
        moveFocusToElementIf(inputYear, type === 'month' && val.length === 2);
        moveFocusToElementIf(inputHour, type === 'year' && val.length === 4);
        moveFocusToElementIf(inputMinute, type === 'hour' && val.length === 2);
        const nextState = { ...internalState, [type]: val };

        setInternalState(nextState);
        const nextValue = convertStateToValue(
          nextState,
          Boolean(isDateOnly),
          defaultValues
        );
        onChange(nextValue, nextState);
      } catch (e) {
        // silent
      }
    },
    [defaultValues, internalState, isDateOnly, onChange]
  );

  const effectivePlaceholder = isStateEmpty(internalState) ? placeholder : null;

  const commonInputProps = {
    name: props.name,
    type: 'text' as const,
    inputMode: 'numeric' as const,
    onFocus: handleFocus,
    onBlur: handleBlur,
    disabled,
    pattern: '[0-9]*',
  };

  return (
    <div
      className="DateInput-wrapper"
      id={id}
      data-testid={props['data-testid']}
    >
      <input
        {...commonInputProps}
        className="DateInput-day"
        placeholder={effectivePlaceholder?.day ?? defaultValues?.day ?? 'DD'}
        ref={inputDay}
        value={internalState.day}
        onChange={handleChange('day')}
      />
      <input
        {...commonInputProps}
        className="DateInput-month"
        ref={inputMonth}
        placeholder={
          effectivePlaceholder?.month ?? defaultValues?.month ?? 'MM'
        }
        value={internalState.month}
        onChange={handleChange('month')}
        onKeyDown={handleKeyDown('month')}
      />
      <input
        {...commonInputProps}
        className="DateInput-year"
        ref={inputYear}
        placeholder={
          effectivePlaceholder?.year ?? defaultValues?.year ?? 'YYYY'
        }
        value={internalState.year}
        onChange={handleChange('year')}
        onKeyDown={handleKeyDown('year')}
      />
      {isDateOnly ? null : (
        <>
          <input
            {...commonInputProps}
            className="DateInput-hour"
            ref={inputHour}
            placeholder={
              effectivePlaceholder?.hour ?? defaultValues?.hour ?? 'HH'
            }
            value={internalState.hour}
            onChange={handleChange('hour')}
            onKeyDown={handleKeyDown('hour')}
          />
          <span className="Hour-colon">:</span>
          <input
            {...commonInputProps}
            className="DateInput-minute"
            ref={inputMinute}
            placeholder={
              effectivePlaceholder?.minute ?? defaultValues?.minute ?? 'mm'
            }
            value={internalState.minute}
            onChange={handleChange('minute')}
            onKeyDown={handleKeyDown('minute')}
          />
        </>
      )}
    </div>
  );
});

export function convertValueToState(
  val: string,
  isDateOnly: boolean
): DateInputState {
  if (val) {
    const dateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(Z)?$/;
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    const isValid = isDateOnly ? dateRegex.test(val) : dateTimeRegex.test(val);

    if (isValid) {
      const matches = val.match(/\d+/g);

      if (matches && matches.length) {
        const [year, month, day, hour, minute] = matches;
        return {
          year: year || '',
          month: month || '',
          day: day || '',
          hour: hour || '',
          minute: minute || '',
        };
      }
    }
  }
  return emptyState();
}

export function convertStateToValue(
  state: DateInputState,
  isDateOnly: boolean,
  defaultValues?: Partial<DateInputState> | null
): string {
  const { day, month, year, hour, minute } = state;

  if (defaultValues === null && !(day && month && year && hour && minute)) {
    return '';
  }

  if (day || month || year || hour || minute) {
    const dateString = `${pad4(year || defaultValues?.year || '0000')}-${pad2(
      month || defaultValues?.month || '01'
    )}-${pad2(day || defaultValues?.day || '01')}`;
    const hourString = `${pad2(hour || defaultValues?.hour || '00')}:${pad2(
      minute || defaultValues?.minute || '00'
    )}:00`;

    return isDateOnly ? dateString : `${dateString}T${hourString}`;
  }

  return '';
}

function emptyState(): DateInputState {
  return {
    day: '',
    month: '',
    year: '',
    hour: '',
    minute: '',
  };
}

const pad2 = (str: string | number) => padStart(String(str), 2, '0');
const pad4 = (str: string | number) => padStart(String(str), 4, '0');

function getValidValue(
  e: ChangeEvent<HTMLInputElement>,
  maxLen: number,
  range?: [number, number]
) {
  // make sure that user do not enter text
  if (!e.target.validity.valid) {
    throw new Error();
  }
  const rawValue = e.target.value;

  if (rawValue.length > maxLen) {
    throw new Error();
  }

  const value = parseInt(e.target.value);

  if (range) {
    if (value < range[0] || value > range[1]) {
      throw new Error();
    }
  }

  return rawValue;
}

function moveFocusToElementIf(
  el: RefObject<null | HTMLInputElement>,
  condition: boolean
) {
  if (condition) {
    el.current?.focus();
    el.current?.select();
    return true;
  }
  return false;
}

function isStateEmpty(state: DateInputState) {
  return Object.keys(state).every((key) => !state[key]);
}
