import React from 'react';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { Trans, t } from '@lingui/macro';
import ActionBlock from 'components/base/actionblock/actionblock';
import { AlertInfo, AlertDanger } from 'components/base/alert/alert';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import { Model } from 'util/backendapi/models/api.interfaces';
import Button from 'components/base/button/button';
import { StaticDataReportState } from 'ducks/static-data-report';
import Loading from 'components/base/loading/loading';
import {
  ExportPanelModalButton,
  GetExportUrlFunc,
} from 'components/modules/exportpanel/exportpanel';
import SimpleSelect from 'components/base/form/simpleselect/simpleselect';
import {
  StaticDataReportModal,
  StaticDataReportModalSettings,
} from './StaticDataReportModal';
import './StaticDataReportView.scss';
import { Icon } from 'components/base/icon/icon';
import range from 'lodash/range';
import { ExportFormats } from 'components/modules/exportpanel/exportpanelconstants';

export type StaticDataReportViewProps = {
  setActiveObservationPointIdQueryParam: (observationPointId: number) => void;
  setStaticDataReportQueryParams: (
    areas: number[],
    sites: number[],
    observationPoints: number[],
    start_datetime: string,
    end_datetime: string
  ) => void;
  getExportUrl: GetExportUrlFunc;
  settings: StaticDataReportModalSettings | null;
  allObservationPointOptions: { value: number; label: string }[];
  activeObservationPointId: number | null;
  result: StaticDataReportState['result'];
  isLoading: StaticDataReportState['isLoading'];
  errorMessage: StaticDataReportState['errorMessage'];
  isExporting?: boolean;
};

const StaticDataReportHeader = (props: {
  allObservationPointOptions: StaticDataReportViewProps['allObservationPointOptions'];
  setActiveObservationPointIdQueryParam: StaticDataReportViewProps['setActiveObservationPointIdQueryParam'];
  activeObservationPointId: StaticDataReportViewProps['activeObservationPointId'];
}) => {
  const {
    allObservationPointOptions,
    activeObservationPointId,
    setActiveObservationPointIdQueryParam,
  } = props;
  const reportObsPointIdx = allObservationPointOptions.findIndex(
    (option) => option.value === activeObservationPointId
  );

  return (
    <div className="static-data-header">
      <h2>{allObservationPointOptions[reportObsPointIdx].label}</h2>
      <ActionBlock className="static-data-pager">
        <Button
          iconOnly
          iconType="icon-arrow-left"
          title={t`Previous observation point in report`}
          disabled={reportObsPointIdx === 0}
          onClick={() =>
            setActiveObservationPointIdQueryParam(
              allObservationPointOptions[reportObsPointIdx - 1].value
            )
          }
        />
        <SimpleSelect
          name="currentObservationPoint"
          options={allObservationPointOptions}
          onChange={(observationPointId: number | null) =>
            setActiveObservationPointIdQueryParam(observationPointId!)
          }
          value={activeObservationPointId!}
        />
        <Button
          iconOnly
          iconType="icon-arrow-right"
          title={t`Next observation point in report`}
          disabled={reportObsPointIdx === allObservationPointOptions.length - 1}
          onClick={() =>
            setActiveObservationPointIdQueryParam(
              allObservationPointOptions[reportObsPointIdx + 1].value
            )
          }
        />
      </ActionBlock>
    </div>
  );
};

export function StaticDataReportView(props: StaticDataReportViewProps) {
  const {
    isLoading,
    errorMessage,
    allObservationPointOptions,
    activeObservationPointId,
    result,
    setActiveObservationPointIdQueryParam,
  } = props;
  let reportContent = (
    <AlertInfo>
      <Trans>Please provide settings for the static data report.</Trans>
    </AlertInfo>
  );

  if (errorMessage) {
    reportContent = <AlertDanger>{errorMessage}</AlertDanger>;
  } else if (isLoading) {
    reportContent = <Loading />;
  } else if (
    !isLoading &&
    result &&
    allObservationPointOptions.length &&
    (activeObservationPointId || props.isExporting)
  ) {
    reportContent = (
      <>
        {props.isExporting ? null : (
          <StaticDataReportHeader
            activeObservationPointId={activeObservationPointId}
            setActiveObservationPointIdQueryParam={
              setActiveObservationPointIdQueryParam
            }
            allObservationPointOptions={allObservationPointOptions}
          />
        )}
        <StaticDataResultsTable
          results={result}
          allObservationPointOptions={allObservationPointOptions}
          isExporting={props.isExporting}
        />
      </>
    );
  }

  return (
    <PageStandard
      name="static-data-report"
      header={props.isExporting ? null : <Trans>Static Data Report</Trans>}
    >
      {props.isExporting ? null : (
        <ActionBlock className="text-right">
          <ButtonShowModal
            name="static-data-report-settings"
            modalContent={() => (
              <StaticDataReportModal
                settings={props.settings!}
                setStaticDataReportQueryParams={
                  props.setStaticDataReportQueryParams
                }
              />
            )}
            autoShow={!allObservationPointOptions.length}
          >
            <Trans>Settings</Trans>
          </ButtonShowModal>
          <ExportPanelModalButton
            canExportXlsx={true}
            canExportPdf={true}
            taskQueueFormats={[ExportFormats.PDF, ExportFormats.XLSX]}
            getExportUrl={props.getExportUrl}
            canSelectPageOrientation={true}
          />
        </ActionBlock>
      )}
      {reportContent}
    </PageStandard>
  );
}
const StaticDataResultsTable = (props: {
  results: Model.StaticDataReportInstance[] | null;
  isExporting?: boolean;
  allObservationPointOptions: StaticDataReportViewProps['allObservationPointOptions'];
}) => {
  if (props.results) {
    return (
      <>
        {props.results.map((result, resultIndex) => {
          const { observation_point_code: observationPointCode, sections } =
            result;

          return (
            <div
              className="observation-point-results"
              key={`observation-point-results-${observationPointCode}-${resultIndex}`}
            >
              {resultIndex > 0 ? <div className="result-separator" /> : null}
              {props.isExporting ? (
                <h3 className="pdf-heading">
                  <Trans>Static Data Report: {observationPointCode}</Trans>
                </h3>
              ) : null}
              {sections.map((section, sectionIndex) => {
                const {
                  site_details,
                  observation_point_details,
                  install_details,
                  reading_details,
                  formula_details,
                  logger_details,
                } = section;

                const formulaValues = [
                  ...formula_details.formula_inputs,
                  ...formula_details.formula_constant_values,
                ];

                // The first row of Formula details has two columns for formula values
                // An additional row is then created for each additional group of up to 3 formula values
                const formulaValueAdditionalRowCount = Math.max(
                  0,
                  Math.ceil((formulaValues.length - 2) / 3)
                );

                return (
                  <div key={`section-${observationPointCode}-${sectionIndex}`}>
                    {sectionIndex > 0 ? (
                      <div className="section-separator" />
                    ) : null}
                    <div className="section-time-period">
                      <Trans>
                        <Icon type="icon-calendar" /> {section.start_datetime}
                        {' - '}
                        {section.end_datetime}
                      </Trans>
                    </div>
                    <h3>
                      <Trans>Site Details for {observationPointCode}</Trans>
                    </h3>
                    <div
                      className="filtered-table-container"
                      key={`site-details-${observationPointCode}-${sectionIndex}`}
                    >
                      <table>
                        <tbody>
                          <tr>
                            <th>
                              <Trans>Site</Trans>
                            </th>
                            <td>{site_details.site_code}</td>
                            <th>
                              <Trans>Area</Trans>
                            </th>
                            <td>{site_details.area_name}</td>
                            <th>
                              <Trans>Location</Trans>
                            </th>
                            <td>{site_details.location}</td>
                          </tr>
                          <tr>
                            <th>
                              <Trans>Cap RL</Trans>
                            </th>
                            <td>{site_details.cap_rl}</td>
                            <th>
                              <Trans>Ground RL</Trans>
                            </th>
                            <td>{site_details.ground_rl}</td>
                            <th>
                              <Trans>As built documentation</Trans>
                            </th>
                            <td>{site_details.asbuilt ? 'Yes' : 'No'}</td>
                          </tr>
                          <tr>
                            <th>
                              <Trans>As built angle</Trans>
                            </th>
                            <td>{site_details.asbuilt_angle}</td>
                            <th>
                              <Trans>As built azimuth</Trans>
                            </th>
                            <td>{site_details.asbuilt_azimuth}</td>
                            <th>
                              <Trans>As built length</Trans>
                            </th>
                            <td>{site_details.asbuilt_length}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <h3>
                      <Trans>
                        Observation Point Details for {observationPointCode}
                      </Trans>
                    </h3>
                    <div
                      className="filtered-table-container"
                      key={`observation-point-details-${observationPointCode}-${sectionIndex}`}
                    >
                      <table>
                        <tbody>
                          <tr>
                            <th>
                              <Trans>Observation point</Trans>
                            </th>
                            <td>{observation_point_details.code}</td>
                            <th>
                              <Trans>Name</Trans>
                            </th>
                            <td>{observation_point_details.name}</td>
                            <th>
                              <Trans>Classification</Trans>
                            </th>
                            <td>
                              {observation_point_details.classification_code}
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <Trans>Performance characteristics</Trans>
                            </th>
                            <td>
                              {
                                observation_point_details.performance_characteristics
                              }
                            </td>
                            <th>
                              <Trans>Site</Trans>
                            </th>
                            <td>{observation_point_details.site_code}</td>
                            <th>
                              <Trans>Area</Trans>
                            </th>
                            <td>{observation_point_details.area_name}</td>
                          </tr>
                          <tr>
                            <th>
                              <Trans>Grid reference</Trans>
                            </th>
                            <td>
                              {observation_point_details.grid_reference_code}
                            </td>
                            <th>
                              <Trans>Northing/Latitude</Trans>
                            </th>
                            <td>{observation_point_details.grid_y}</td>
                            <th>
                              <Trans>Easting/Longitude</Trans>
                            </th>
                            <td>{observation_point_details.grid_x}</td>
                          </tr>
                          <tr>
                            <th>
                              <Trans>Instrument type</Trans>
                            </th>
                            <td>
                              {observation_point_details.instrument_type_code}
                            </td>
                            <th>
                              <Trans>Instrument reliability</Trans>
                            </th>
                            <td>
                              {observation_point_details.reliability_code}
                            </td>
                            <th>
                              <Trans>Extensometer length</Trans>
                            </th>
                            <td>
                              {observation_point_details.extensometer_length}
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <Trans>Instrument comment</Trans>
                            </th>
                            <td>
                              {observation_point_details.instrument_comment}
                            </td>
                            <th>
                              <Trans>Serial number</Trans>
                            </th>
                            <td>{observation_point_details.serial_number}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <h3>
                      <Trans>Install Details for {observationPointCode}</Trans>
                    </h3>
                    <div
                      className="filtered-table-container"
                      key={`install-details-${observationPointCode}-${sectionIndex}`}
                    >
                      <table>
                        <tbody>
                          <tr>
                            <th>
                              <Trans>Install date</Trans>
                            </th>
                            <td>{install_details.install_date}</td>
                            <th>
                              <Trans>First read</Trans>
                            </th>
                            <td>{install_details.first_read}</td>
                            <th>
                              <Trans>Base date</Trans>
                            </th>
                            <td>{install_details.base_date}</td>
                          </tr>
                          <tr>
                            <th>
                              <Trans>Install depth</Trans>
                            </th>
                            <td>{install_details.install_depth}</td>
                            <th>
                              <Trans>Filter top</Trans>
                            </th>
                            <td>{install_details.filter_top}</td>
                            <th>
                              <Trans>Filter bottom</Trans>
                            </th>
                            <td>{install_details.filter_bottom}</td>
                          </tr>
                          <tr>
                            <th>
                              <Trans>Tubing type</Trans>
                            </th>
                            <td>{install_details.tubing_type_code}</td>
                            <th>
                              <Trans>Internal diameter</Trans>
                            </th>
                            <td>{install_details.internal_diameter}</td>
                            <th>
                              <Trans>Probed depth</Trans>
                            </th>
                            <td>{install_details.probed_depth}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <h3>
                      <Trans>Reading Details for {observationPointCode}</Trans>
                    </h3>
                    <div
                      className="filtered-table-container"
                      key={`reading-details-${observationPointCode}-${sectionIndex}`}
                    >
                      <table>
                        <tbody>
                          <tr>
                            <th>
                              <Trans>First reading</Trans>
                            </th>
                            <td>{reading_details.first_reading_datetime}</td>
                            <th>
                              <Trans>Latest reading</Trans>
                            </th>
                            <td>{reading_details.latest_reading_datetime}</td>
                            <th>
                              <Trans>Next reading</Trans>
                            </th>
                            <td>{reading_details.next_reading_datetime}</td>
                          </tr>
                          <tr>
                            <th>
                              <Trans>Active reading frequency</Trans>
                            </th>
                            <td>{reading_details.active_reading_frequency}</td>
                            <th>
                              <Trans>Default reading frequency</Trans>
                            </th>
                            <td>{reading_details.reading_frequency}</td>
                            <th>
                              <Trans>Frequency tolerance</Trans>
                            </th>
                            <td>
                              {reading_details.reading_frequency_tolerance}
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <Trans>Device alarm</Trans>
                            </th>
                            <td>{reading_details.device_alarm_threshold}</td>
                            <th>
                              <Trans>Route march instruction</Trans>
                            </th>
                            <td>{reading_details.route_march_instruction}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <h3>
                      <Trans>Formula Details for {observationPointCode}</Trans>
                    </h3>
                    <div
                      className="filtered-table-container"
                      key={`formula-details-${observationPointCode}-${sectionIndex}`}
                    >
                      <table>
                        <tbody>
                          <tr>
                            <th>
                              <Trans>Formula</Trans>
                            </th>
                            <td>{formula_details.current_formula}</td>
                            <th>{formulaValues[0]?.label}</th>
                            <td>{formulaValues[0]?.value}</td>
                            <th>{formulaValues[1]?.label}</th>
                            <td>{formulaValues[1]?.value}</td>
                          </tr>
                          {/* Create additional rows for each 3 additional formula inputs and/or constant values */}
                          {range(0, formulaValueAdditionalRowCount).map(
                            (rowOffset) => {
                              const rowFirstIndex = 2 + rowOffset * 3;
                              const rowSecondIndex = rowFirstIndex + 1;
                              const rowThirdIndex = rowFirstIndex + 2;
                              return (
                                <tr
                                  key={`formula-values-${observationPointCode}-${sectionIndex}-${rowOffset}`}
                                >
                                  <th>{formulaValues[rowFirstIndex]?.label}</th>
                                  <td>{formulaValues[rowFirstIndex]?.value}</td>
                                  <th>
                                    {formulaValues[rowSecondIndex]?.label}
                                  </th>
                                  <td>
                                    {formulaValues[rowSecondIndex]?.value}
                                  </td>
                                  <th>{formulaValues[rowThirdIndex]?.label}</th>
                                  <td>{formulaValues[rowThirdIndex]?.value}</td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                    {logger_details.logger_number ? (
                      <>
                        <h3>
                          <Trans>
                            Data Logger Details for {observationPointCode}
                          </Trans>
                        </h3>
                        <div
                          className="filtered-table-container"
                          key={`data-logger-details-${observationPointCode}-${sectionIndex}`}
                        >
                          <table>
                            <tbody>
                              <tr>
                                <th>
                                  <Trans>Data Logger ID</Trans>
                                </th>
                                <td>{logger_details.logger_number}</td>
                                <th>
                                  <Trans>Channel</Trans>
                                </th>
                                <td>{logger_details.channel_number}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : null}
                  </div>
                );
              })}
            </div>
          );
        })}
      </>
    );
  }
  return <p>no results</p>;
};
