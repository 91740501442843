import { t, Trans } from '@lingui/macro';
import LatLonSpherical from 'geodesy/latlon-spherical';
import sortBy from 'lodash/sortBy';
import React, { RefObject, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { XYPlot, YAxis, LineSeries } from 'react-vis';
import { scaleLinear, ScaleLinear } from 'd3-scale';

import { AlertWarning } from 'components/base/alert/alert';
import { showHideToggleOptions } from 'components/base/form/toggle-field/ToggleField';
import { Toggle } from 'components/base/form/toggle/Toggle';
import Loading from 'components/base/loading/loading';
import CrosshairWithReticle from 'components/plots/crosshairwithreticle';
import { LabelSeries, CustomSVGSeries } from 'components/plots/react-vis-hacks';
import { FullState } from 'main/reducers';
import {
  LatLong,
  SpatialPlotObservationPoint,
} from 'util/backendapi/types/Model';
import { useShallowEqualSelector } from 'util/hooks';
import {
  calculateCrossSectionOrigin,
  ICON_MARKER_OBSERVATION_WELL,
  ICON_MARKER_WATER_LEVEL_BASELINE,
  calculateSpatialPlotDomains,
} from './spatial-plot-utils';
import { useSpatialPlotHover } from './useSpatialPlotHover';
import { useSpatialPlotResizeObserver } from './useSpatialPlotResizeObserver';
import { SpatialPlotDataTable } from './SpatialPlotDataTable';
import { SpatialPlotObsPointPopup } from './SpatialPlotObsPointPopup';

import './spatial-plot.scss';
import { isNotNull, isTruthy } from 'util/validation';
import { Popup } from 'components/plots/popups/Popup';
import { StoredSpatialCrossSectionPlotWithArea } from 'ducks/stored-plot/detail';
import { selectSpatialPlotData } from 'ducks/plot/spatial';
import { i18n } from '@lingui/core';
import { ScaleRule } from './ScaleRule';

// Because we draw the icons using fonts, they're actually LabelSeries. But
// providing an IconSeries alias hopefully makes the code a little easier to read.
const IconSeries = LabelSeries;

const PLOT_MARGINS = {
  // Provide 50px left/right margin, to allow for scale tick labels
  left: 55,
  right: 55,
  // Provide 10px top/bottom margin, so that scale tick labels won't get cut off
  // if they're near the top or bottom of the plot
  top: 10,
  bottom: 10,
};

// Vertical padding (in px) between the mark representing the obs point's port RL,
// and its corresponding text label.
const PORT_RL_LABEL_Y_OFFSET = 3;
// Padding between mark for obs point's water level and its text label.
const WATER_LEVEL_LABEL_Y_OFFSET = -6;

// Size of the SVG we use for the water level icons here
const WATER_LEVEL_SYMBOL_WIDTH = 14 * 0.6;
const WATER_LEVEL_SYMBOL_HEIGHT = 11 * 0.6;

type CrossSectionViewPlotPoint = Merge<
  Omit<SpatialPlotObservationPoint, 'vertical_offset' | 'horizontal_offset'>,
  {
    wgs84_coordinates: LatLong;
    port_rl: number;
    code: string;
    label: string | null;
    x: number;
    y: number;
    vertical_offset: number;
    horizontal_offset: number;
  }
>;

export function SpatialCrossSectionPlot(props: {
  storedPlot: StoredSpatialCrossSectionPlotWithArea;
  buttonsPortal?: RefObject<HTMLElement>;
}) {
  const { storedPlot } = props;

  const plotState = useShallowEqualSelector((state: FullState) =>
    selectSpatialPlotData(state, storedPlot.id)
  );

  if (plotState?.isLoading) {
    return <Loading />;
  } else if (plotState?.errorMessage) {
    return <AlertWarning>{plotState.errorMessage}</AlertWarning>;
  } else if (!plotState?.observationPoints || !storedPlot) {
    return null;
  }

  return (
    <SpatialCrossSectionPlotView
      buttonsPortal={props.buttonsPortal}
      obsPoints={plotState.observationPoints as SpatialPlotObservationPoint[]}
      storedPlot={storedPlot}
    />
  );
}

export interface SpatialCrossSectionPlotViewProps {
  storedPlot: StoredSpatialCrossSectionPlotWithArea;
  obsPoints: SpatialPlotObservationPoint[];
  buttonsPortal?: RefObject<HTMLElement>;
  initialHeight?: number;
  dataTablePos?: {
    top: string;
    left: string;
  };
}

export function SpatialCrossSectionPlotView(
  props: SpatialCrossSectionPlotViewProps
) {
  const { storedPlot, obsPoints } = props;

  const baseObsPoint = obsPoints.find(
    (op) => op.id === storedPlot.base_observation_point
  );

  if (!baseObsPoint) {
    return (
      <AlertWarning>
        <Trans>Error: Could not load base observation point.</Trans>
      </AlertWarning>
    );
  } else if (!baseObsPoint.wgs84_coordinates) {
    return (
      <AlertWarning>
        <Trans>
          Error: Base observation point {baseObsPoint.code} does not have
          geographic location data.
        </Trans>
      </AlertWarning>
    );
  } else if (baseObsPoint.port_rl === null) {
    return (
      <AlertWarning>
        <Trans>
          Error: Base observation point {baseObsPoint.code} does not have a Port
          RL value.
        </Trans>
      </AlertWarning>
    );
  }

  let bearingPoint: LatLong;

  if (storedPlot.bearing_wgs84_coordinates) {
    // manually entered Lat/Long for bearing
    bearingPoint = storedPlot.bearing_wgs84_coordinates;
  } else {
    // an observation point for bearing
    const bearingObsPoint =
      storedPlot.bearing_observation_point &&
      obsPoints.find((op) => op.id === storedPlot.bearing_observation_point);

    if (!bearingObsPoint) {
      return (
        <AlertWarning>
          <Trans>Error: Could not load bearing observation point.</Trans>
        </AlertWarning>
      );
    }
    if (!bearingObsPoint.wgs84_coordinates) {
      return (
        <AlertWarning>
          <Trans>
            Error: Bearing observation point {bearingObsPoint.code} does not
            have geographic location data.
          </Trans>
        </AlertWarning>
      );
    }

    bearingPoint = bearingObsPoint.wgs84_coordinates;
  }

  return (
    <Inner
      {...props}
      bearingPoint={bearingPoint}
      baseObsPoint={baseObsPoint as InnerProps['baseObsPoint']}
    />
  );
}

type InnerProps = SpatialCrossSectionPlotViewProps & {
  baseObsPoint: SpatialPlotObservationPoint & {
    port_rl: number;
    wgs84_coordinates: LatLong;
  };
  bearingPoint: LatLong;
};

function Inner(props: InnerProps) {
  const {
    baseObsPoint,
    bearingPoint,
    storedPlot,
    obsPoints,
    buttonsPortal,
    initialHeight,
    dataTablePos,
  } = props;

  //
  // Calculate the data domain of the plot.
  //
  // Y axis: Elevation (in m) of obs point port RLs and water level readings
  // X axis: Distance (in m) from the base obs point, measured along the plane
  //     of the cross-section.
  //
  const origin = useMemo(
    () => calculateCrossSectionOrigin(storedPlot, baseObsPoint),
    [baseObsPoint, storedPlot]
  );
  const [xDomain, yDomain] = calculateSpatialPlotDomains(storedPlot, origin);

  //
  // Calculate where to place the markers on the plot for the observation points
  //
  const obsPointPlotPoints = useMemo(
    () =>
      calculateCrossSectionPositions(
        baseObsPoint,
        bearingPoint,
        obsPoints,
        storedPlot,
        xDomain
      ),
    [baseObsPoint, bearingPoint, obsPoints, storedPlot, xDomain]
  );

  const {
    obsPointIcons,
    obsPointLabels,
    waterLevelIcons,
    waterLevelLabels,
    connectionLines,
  } = useMemo(() => {
    const obsPointsXY = obsPointPlotPoints
      .map((op) =>
        op.port_rl === null ||
        op.port_rl < yDomain[0] ||
        op.port_rl > yDomain[1]
          ? null
          : {
              ...op,
              y: op.port_rl,
              vertical_offset: +op.vertical_offset || 0,
              horizontal_offset: +op.horizontal_offset || 0,
            }
      )
      .filter(isNotNull) as CrossSectionViewPlotPoint[];

    const waterLevels = obsPointsXY
      .map((op) => {
        const waterLevel =
          op.reading?.adjusted_reading_entries[0]?.value ?? null;
        const waterLevelAsNumber = Number(waterLevel);
        if (
          waterLevel === null ||
          waterLevelAsNumber < yDomain[0] ||
          waterLevelAsNumber > yDomain[1]
        ) {
          return null;
        }
        return {
          ...op,
          y: waterLevelAsNumber,
        };
      })
      .filter(isNotNull);

    return {
      obsPointIcons: obsPointsXY.map((p) => ({
        ...p,
        x: p.x + p.horizontal_offset,
        y: p.y + p.vertical_offset,
        plotId: `${p.id}-icon`,
        label: ICON_MARKER_OBSERVATION_WELL,
      })),
      obsPointLabels: obsPointsXY
        .filter((p) => p.label !== null)
        .map((p) => ({
          ...p,
          x: p.x + p.horizontal_offset,
          y: p.y + p.vertical_offset,
          yOffset: PORT_RL_LABEL_Y_OFFSET,
        })),
      waterLevelIcons: waterLevels.map((p) => ({
        ...p,
        x: p.x + p.horizontal_offset,
        label: ICON_MARKER_WATER_LEVEL_BASELINE,
      })),
      waterLevelLabels: waterLevels.map((p) => ({
        ...p,
        x: p.x + p.horizontal_offset,
        yOffset: WATER_LEVEL_LABEL_Y_OFFSET,
      })),
      connectionLines: storedPlot.show_connection_lines
        ? storedPlot.connection_groups.map((group) =>
            sortBy(
              group.observation_points
                .map((obsPointId) =>
                  waterLevels.find((wl) => wl.id === obsPointId)
                )
                .filter(isTruthy)
                .map((p) => ({
                  ...p,
                  x: p.x + p.horizontal_offset,
                })),
              (op) => op.x
            )
          )
        : null,
    };
  }, [
    obsPointPlotPoints,
    storedPlot.connection_groups,
    storedPlot.show_connection_lines,
    yDomain,
  ]);

  const [showDataTable, setShowDataTable] = useState<boolean>(
    storedPlot.show_data_table
  );

  const hover = useSpatialPlotHover<CrossSectionViewPlotPoint>();
  const { ref, widthPx, heightPx } = useSpatialPlotResizeObserver(
    storedPlot,
    PLOT_MARGINS,
    initialHeight
  );

  return (
    <>
      {buttonsPortal?.current &&
        createPortal(
          <Toggle
            inline={true}
            className="radio-toggle-data-table"
            options={showHideToggleOptions}
            name="show-data-table-radio"
            label={<Trans>Data table:</Trans>}
            defaultValue={showDataTable}
            onChange={setShowDataTable}
          />,
          buttonsPortal.current
        )}
      <div className="non-cropping-plot-wrapper">
        <div className="plot-area" ref={ref}>
          <XYPlot
            height={heightPx}
            width={widthPx}
            xDomain={xDomain}
            yDomain={yDomain}
            margin={PLOT_MARGINS}
          >
            <ManualSVGContainer {...props} />
            {/* The "adjacent piezo" connection lines */}
            {connectionLines?.map((connectionGroup) => (
              <LineSeries
                key={connectionGroup[0]?.id ?? 0}
                data={connectionGroup}
                className="cross-section-connection-line"
                // Let CSS control the color.
                color=""
              />
            ))}
            {/* A mark at each observation point's Port RL */}
            <IconSeries
              data={obsPointIcons}
              labelAnchorX="middle"
              labelAnchorY="central"
              className="cross-section-port-rl-icon"
              onNearestXY={hover.isHoverable ? hover.onNearestXY : undefined}
            />
            {/* A mark at the level of each obs point's latest reading */}
            <IconSeries
              data={waterLevelIcons}
              labelAnchorX="middle"
              // "baseline" so that the bottom of each icon is positioned precisely
              // at the level of the latest reading's value.
              labelAnchorY="baseline"
              className="cross-section-obs-point-icon"
            />
            {/* Obs point label, below the Port RL mark */}
            <LabelSeries
              data={obsPointLabels}
              labelAnchorX="middle"
              labelAnchorY="hanging"
              allowOffsetToBeReversed={false}
              className="cross-section-obs-point-label"
            />
            {/* Obs point label, next to the latest reading value */}
            <LabelSeries
              data={waterLevelLabels}
              labelAnchorY="text-after-edge"
              className="cross-section-obs-point-value"
            />
            <YAxesTruncated {...props} widthPx={widthPx} />

            {hover.hoverPoint && (
              <CrosshairWithReticle
                showVertical={false}
                showHorizontal={false}
                values={[hover.hoverPoint]}
                onReticleClick={(point, position) => {
                  hover.setSelectedPoint({
                    ...point,
                    position,
                  });
                  hover.setIsHoverable(false);
                }}
              />
            )}
            {hover.selectedPoint && (
              <Popup point={hover.selectedPoint}>
                <SpatialPlotObsPointPopup
                  onClose={hover.onClosePopup}
                  observationPoints={[hover.selectedPoint]}
                />
              </Popup>
            )}
          </XYPlot>
        </div>
        {showDataTable && (
          <div
            style={{
              position: 'absolute',
              top: dataTablePos?.top ?? `${20 + PLOT_MARGINS.top}px`,
              left: dataTablePos?.left ?? `${20 + PLOT_MARGINS.left}px`,
            }}
          >
            <SpatialPlotDataTable
              storedPlot={storedPlot}
              obsPoints={obsPoints}
            />
          </div>
        )}
      </div>
    </>
  );
}

/**
 * Calculate where each observation point should be located in the cross-section
 * diagram.
 *
 * @param baseObsPoint
 * @param bearingPoint
 * @param obsPoints
 * @param storedPlot
 * @param xDomain
 * @param yDomain
 */
function calculateCrossSectionPositions(
  baseObsPoint: InnerProps['baseObsPoint'],
  bearingPoint: InnerProps['bearingPoint'],
  obsPoints: InnerProps['obsPoints'],
  storedPlot: InnerProps['storedPlot'],
  xDomain: [number, number]
) {
  const baseLatLong = new LatLonSpherical(
    baseObsPoint.wgs84_coordinates.lat,
    baseObsPoint.wgs84_coordinates.lng
  );
  const bearingLatLong = new LatLonSpherical(
    bearingPoint.lat,
    bearingPoint.lng
  );

  const points = obsPoints
    .map((op) => {
      // Filter out obs points with no geo coordinates and/or Port RL
      if (!op.wgs84_coordinates) {
        return null;
      }
      const opConfig = storedPlot.observation_points.find(
        (sop) => sop.observation_point === op.id
      );

      // X axis: Distance (in meters) from the base observation point, along
      // the line that runs through the base obs point and the bearing point.
      let x: number = new LatLonSpherical(
        op.wgs84_coordinates.lat,
        op.wgs84_coordinates.lng
      ).alongTrackDistanceTo(baseLatLong, bearingLatLong);

      // BUG: the geodesy library returns `NaN` rather than `0` for
      // along-track distance, when the starting point and the ending point
      // are the same location. (For example, two obs points at different
      // depths in the same observation well.)
      //
      // See: https://github.com/chrisveness/geodesy/issues/76
      if (Number.isNaN(x)) {
        x = 0;
      }

      return {
        ...op,
        x,
        label: opConfig?.show_label ? opConfig?.label! : null,
      };
    })
    // Filter out points that would be outside the plot.
    .filter(
      (p): p is NonNullable<typeof p> =>
        p !== null && p.x >= xDomain[0] && p.x <= xDomain[1]
    );
  return sortBy(points, 'x');
}

/**
 * A container function to render low-level SVG elements that can't be handled
 * very gracefully by a React-Vis series. (Although it would be possible to
 * do it hackily with a CustomSVGSeries that has a single data point at the
 * upper-left of the plot.)
 *
 * @param props
 */
function ManualSVGContainer(
  props: SpatialCrossSectionPlotViewProps & {
    // React-Vis will automagically pass these props in via child prop
    // rewriting.
    innerWidth?: number;
    innerHeight?: number;
    xRange?: [number, number];
    yRange?: [number, number];
    yDomain?: [number, number];
    marginTop?: number;
    marginLeft?: number;
  }
) {
  const {
    storedPlot,
    innerWidth,
    innerHeight,
    marginLeft,
    marginTop,
    xRange,
    yRange,
    yDomain,
  } = props;

  const { xScale, yScale } = useMemo(
    () => ({
      xScale: scaleLinear()
        // The X coordinate of the water level lines is provided to us in paperspace mm.
        .domain([0, +storedPlot.paperspace_width])
        .range(xRange!),
      yScale: scaleLinear()
        // The Y coordinate of the water level lines is in reading value meters;
        // the same as the rest of the plot.
        .domain(yDomain!)
        .range(yRange!),
    }),
    [storedPlot.paperspace_width, xRange, yRange, yDomain]
  );

  return (
    <g transform={`translate(${marginLeft!} ${marginTop!})`}>
      {/* The plot's background images using normal <img /> tag */}
      {/* The SVG <image href /> tag will not render in SSR */}
      {/* Need to wrap inside SVG otherwise it wont render correctly in SSR */}
      <svg x={0} y={0} width={innerWidth} height={innerHeight}>
        <foreignObject x="0" y="0" width="100%" height="100%">
          <img src={storedPlot.base_layer_image} width="100%" alt="" />
        </foreignObject>
      </svg>
      {storedPlot.additional_layer_image && (
        <svg x={0} y={0} width={innerWidth} height={innerHeight}>
          <foreignObject x="0" y="0" width="100%" height="100%">
            <img src={storedPlot.additional_layer_image!} width="100%" alt="" />
          </foreignObject>
        </svg>
      )}
      <WaterLevelLine
        label={<Trans>Lake level</Trans>}
        obsPointId={storedPlot.lake_level_observation_point}
        startX={storedPlot.lake_level_line_start_x}
        endX={storedPlot.lake_level_line_end_x}
        xScale={xScale}
        yScale={yScale}
        allObsPoints={props.obsPoints}
      />
      <WaterLevelLine
        label={<Trans>Tailwater level</Trans>}
        obsPointId={storedPlot.tailwater_level_observation_point}
        startX={storedPlot.tailwater_level_line_start_x}
        endX={storedPlot.tailwater_level_line_end_x}
        xScale={xScale}
        yScale={yScale}
        allObsPoints={props.obsPoints}
      />
      <ScaleRule {...props} />
    </g>
  );
}
// React-Vis requires this attribute to be present.
ManualSVGContainer.requiresSVG = true;

/**
 * Render the "Lake level" or "Tailwater level" on the plot.
 *
 * @param props
 */
function WaterLevelLine(props: {
  label: React.ReactElement;
  obsPointId: null | number;
  startX: null | string;
  endX: null | string;
  xScale: ScaleLinear<number, number>;
  yScale: ScaleLinear<number, number>;
  allObsPoints: SpatialCrossSectionPlotViewProps['obsPoints'];
}) {
  const { allObsPoints, obsPointId, startX, endX, xScale, yScale, label } =
    props;

  if (obsPointId === null || startX === null || endX === null) {
    return null;
  }

  const waterLevel =
    allObsPoints.find((op) => op.id === obsPointId)?.reading
      ?.adjusted_reading_entries[0]?.value ?? null;

  if (waterLevel === null) {
    return null;
  }

  const lineY = yScale(+waterLevel);
  const lineX1 = xScale(+startX);
  const lineX2 = xScale(+endX);

  return (
    <>
      {/* The line under the icon */}
      <line
        x1={lineX1}
        y1={lineY}
        x2={lineX2}
        y2={lineY}
        className="cross-section-water-level-line"
      />
      {/* Water level icon (using svg tags rather than a font icon to make the tip
          of the triangle align precisely with the water level.) */}
      <svg
        width={WATER_LEVEL_SYMBOL_WIDTH}
        height={WATER_LEVEL_SYMBOL_HEIGHT}
        x={lineX1}
        y={lineY - WATER_LEVEL_SYMBOL_HEIGHT}
        viewBox="0 0 14 11"
      >
        <polygon
          className="cross-section-water-level-icon"
          points="0 0 7 11 14 0 0 0"
        />
      </svg>
      <text
        x={lineX1 + 2 + WATER_LEVEL_SYMBOL_WIDTH}
        y={lineY}
        dominantBaseline="baseline"
        className="cross-section-water-level-value"
      >
        {/* Current water level */}
        <tspan dy="-0.5em">{waterLevel}</tspan>
      </text>
      {/* Label under the line (e.g. "Tailwater level") */}
      <text
        x={lineX1}
        y={lineY}
        dominantBaseline="hanging"
        className="cross-section-water-level-text"
      >
        {label}
      </text>
    </>
  );
}

/**
 * React-Vis's <YAxis> component doesn't have an option to only reach halfway up
 * the plot. It always goes the fullway. They do provide a <DecorativeAxis>
 * component which can be set to an arbitrary size and position, but it's
 * styled differently than <YAxis>, and you can't control which side the ticks
 * are on; they're always on the right-hand side.
 *
 * So, the easiest way to do a Y axis that only goes partway up the plot, is to
 * print a second, smaller plot and position it on top of the first plot.
 *
 * @param props
 */
function YAxesTruncated(
  props: SpatialCrossSectionPlotViewProps & {
    widthPx: number;
    // React-Vis will automagically pass these props in via child prop
    // rewriting.
    xRange?: [number, number];
    yRange?: [number, number];
    yDomain?: [number, number];
    xDomain?: [number, number];
  }
) {
  const { storedPlot, xRange, yRange, yDomain, xDomain, widthPx } = props;

  if (!yDomain || !yRange || !xDomain || !xRange) {
    return null;
  }

  const scaleY = scaleLinear().domain(yDomain).range(yRange);

  const axisYDomain = [
    storedPlot.y_axis_scale_min ? +storedPlot.y_axis_scale_min : yDomain[0],
    storedPlot.y_axis_scale_max ? +storedPlot.y_axis_scale_max : yDomain[1],
  ];

  const yTop = scaleY(axisYDomain[1]);
  const yBottom =
    scaleY(axisYDomain[0]) + PLOT_MARGINS.bottom + PLOT_MARGINS.top;

  return (
    <foreignObject x="0" y={yTop} width="100%" height="100%">
      <XYPlot
        height={yBottom - yTop}
        width={widthPx}
        xDomain={xDomain}
        yDomain={axisYDomain}
        margin={PLOT_MARGINS}
        style={{ overflow: 'visible' }}
      >
        <YAxis orientation="left" />
        <YAxis
          orientation="left"
          position="middle"
          hideLine
          hideTicks
          marginLeft={0}
          title={i18n._(t`Water level in RL(m)`)}
        />
        <YAxis orientation="right" />
        <YAxis
          orientation="right"
          position="middle"
          hideLine
          hideTicks
          marginLeft={PLOT_MARGINS.left + PLOT_MARGINS.right}
          title={i18n._(t`Water level in RL(m)`)}
        />
        {/* The plot won't render anything unless it has at least one Series component with
        at least one item. A CustomSVGSeries with no `customComponent` works for that,
        and doesn't render anything visible. */}
        <CustomSVGSeries data={[{ x: 1, y: 1 }]} />
      </XYPlot>
    </foreignObject>
  );
}
YAxesTruncated.requiresSVG = true;
